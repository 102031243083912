export default (data) => {
  return(
    {
      builderId: data._id ? data._id : "5fb2a5f3ea262c2e14e462ad",
      typeId: data.user ? "user" : "office",
      officeId: data.user ? data.user : data.office,
      primaryColor: data.primaryColor ? data.primaryColor : "#0076b7",
      address: data.address ? data.address : "",
      email: data.email ? data.email : "contacto@novahome.cl",
      facebook: data.facebook ? data.facebook : "https://facebook.com",
      twitter: data.twitter ? data.twitter : "https://twitter.com",
      instagram: data.instagram ? data.instagram : "https://instagram.com",
      favicon: data.favicon ? data.favicon : require("../images/icon.png"),      
      lat: data.lat ? data.lat : "-33.41445803703894 ",
      lng: data.lng ? data.lng : "-70.60181207494709",
      logo: data.logo ? data.logo : require("../images/logo-light.png"),
      logoDark: data.logoDark ? data.logoDark : require("../images/logo-dark.png"),
      movil: data.movil ? data.movil : "+56 9 5019 3634",
      phone: data.phone ? data.phone : "+56 9 9967 6124",
      footerText: data.footerText ? data.footerText : "Somos una empresa con mas de 20 años en el rubro del corretaje inmobiliario",
      home:{
        hero: {
          background: data.home && data.home.hero && data.home.hero.background ? data.home.hero.background : require("../images/template-home-hero-background.jpg"),
          title: data.home && data.home.hero && data.home.hero.title ? data.home.hero.title : "Tenemos propiedades <br /> exclusivas pensadas para tí"
        },        
        properties: {
          title: data.home && data.home.properties && data.home.properties.title ? data.home.properties.title : "Contamos con una selección exclusiva de propiedades.",
          maxProperties: data.home && data.home.properties && data.home.properties.maxProperties ? data.home.properties.maxProperties : 9,
          footer: data.home && data.home.properties && data.home.properties.footer ? data.home.properties.footer : "Estas son solo algunas de las propiedades que tenemos para ofrecerte",
          buttonText: data.home && data.home.properties && data.home.properties.buttonText ? data.home.properties.buttonText : "Ver más"
        },        
        about: {
          banner: {
            image: data.home && data.home.about && data.home.about.banner && data.home.about.banner.image ? data.home.about.banner.image : require("../images/template-home-about-hero-background.jpg"),
            title: data.home && data.home.about && data.home.about.banner && data.home.about.banner.title ? data.home.about.banner.title : "Somos una empresa con mas de 20 años en el rubro del corretaje inmobiliario",
            subTitle: data.home && data.home.about && data.home.about.banner && data.home.about.banner.subTitle ? data.home.about.banner.subTitle : "Esto nos permite entender mejor que nadie las necesidades de nuestros clientes, por eso ponemos el foco en que la experienca de compra o arriendo sea única",
            buttonText: data.home && data.home.about && data.home.about.banner && data.home.about.banner.buttonText ? data.home.about.banner.buttonText : "Conózcanos"
          }
        },  
        services: {
          items: data.home && data.home.services && data.home.services.items.length ? data.home.services.items : [
            {
              title: "Publicidad",
              description: "Gestión publicitaria en más de 22 portales, redes sociales y gráfica en la propiedad."
            },
            {
              title: "Tasación gratuita",
              description: "Tasación virtual realizada por expertos. Obtención de indicadores para el precio de venta."
            },
            {
              title: "Posicionamiento",
              description: ""
            }
          ]
        },  
        reviews: {
          items: data.home && data.home.reviews && data.home.reviews.items.length ? data.home.reviews.items : [
            {
              id: null,
              review: "Una asesoría impecable, me ayudaron en todo momento, desde una tasación gratuita hasta una asesoría legal para la firma de la venta de mi departamento, estoy muy agradecida de NovaHome",
              author: " Elena • Vendedora departamento San Miguel"
            },
            {
              id: null,
              review: "Conozco a Rodrigo y a Felipe hace varios años y ya me han ayudado a vender mas de una propiedad, excelentes personas y profesionales.",
              author: "Gustavo • Vendedor departamento Ñuñoa"
              
            },
            {
              id: null,
              review: "Agradecer a todo el equipo de NovaHome por el gran trabajo realizado, pude vender mi casa al contado y muy rápido, los recomiendo al 100%.",
              author: " Viviana • Vendedora casa Estación Central"
            },
            {
              id: null,
              review: "Quiero expresar mi satisfacción por sus servicios como inmobiliaria, por haber conseguido la venta de mi casa en Maipú. Además, agradecer al gran trabajo de Rodrigo y Felipe, lograron conectar con muchos interesados hasta encontrar al nuevo dueño.",
              author: "Manuela • Vendedora casa Mapú."
            }
          ]
        }                    
      },
      about:{
        hero: {
          background: data.about && data.about.hero && data.about.hero.background ? data.about.hero.background : require("../images/template-about-hero-background.jpg"),
          title: data.about && data.about.hero && data.about.hero.title ? data.about.hero.title : "Un equipo de profesionales especializados en el sector inmobiliario."
        },
        history: {
          background: data.about && data.about.history && data.about.history.background ? data.about.history.background : require("../images/template-about-history-background.jpg"),
          description: data.about && data.about.history && data.about.history.description ? data.about.history.description : "<p>Somos clasihome, una empresa con varios años de trayectoria en el dinámico mercado inmobiliario, reconocidos por un servicio altamente personalizado y eficiente.</p><p>Nuestra gran fortaleza es el profundo conocimiento comercial inmobiliario que nos permite agregar valor a todos los proyectos que emprendemos y servicios que entregamos.<p/>",
          title: data.about && data.about.history && data.about.history.title ? data.about.history.title : "Nuestra Historia"
        },
        description: {
          items: data.about && data.about.description && data.about.description.items.length ? data.about.description.items : [
            {
              title: "Misión",
              description: "En el ámbito de todo negocio inmobiliario, que nuestros clientes nos reconozcan como la mejor opción para exigir el máximo provecho a sus recursos inmobiliario."
            },
            {
              title: "Visión",
              description: "En el ámbito de todo negocio inmobiliario, que nuestros clientes nos reconozcan como la mejor opción para exigir el máximo provecho a sus recursos inmobiliario."
            },
            {
              title: "Valores",
              description: "En el ámbito de todo negocio inmobiliario, que nuestros clientes nos reconozcan como la mejor opción para exigir el máximo provecho a sus recursos inmobiliario."
            }
          ]
        },
        stats: {
          proffesionals: data.about && data.about.stats && data.about.stats.proffesionals ? data.about.stats.proffesionals : 70,
          properties: data.about && data.about.stats && data.about.stats.properties ? data.about.stats.properties : 1000,
          years: data.about && data.about.stats && data.about.stats.years ? data.about.stats.years : 50,
          transactions: data.about && data.about.stats && data.about.stats.transactions ? data.about.stats.transactions : 500
        },
        team: {
          visible: true,
          items: data.about && data.about.team && data.about.team.items.length ? data.about.team.items : [
            {
              avatar: require("../images/Foto 1 RODRIGO.jpeg"),
              cv: "CEO & Gestión Comercial; posee más de 20 años de experiencia en cargos comerciales y asesorías. ",
              email: "ralvareznova@novahome.cl",
              fullName: "Rodrigo Álvarez Nova",
              
            },
            {
              avatar: require("../images/Foto 2 FELIPE.jpg"),
              cv: "Co-Fundador con 7 años de experiencia, especializado en análisis de mercado y negociación.",
              email: "fsalazar@novahome.cl",
              fullName: "Felipe Salazar Álvarez",
              
              
            },
            {
              avatar: require("../images/Foto 3 VIVIANA.jpeg"),
              cv: "Asesora inmobiliaria posee vasta experiencia en asesorías y atención a clientes.",
              email: "vzuniga@novahome.cl",
              fullName: "Viviana Zuñiga Naranjo",
              
            },
            {
              avatar: require ("../images/Foto 4 LUCIANO.jpeg"),
              cv: "Ingeniero en RRHH con 5 años de experiencia como Broker inmobiliario & comercial.",
              email: "lmelandri@novahome.cl",
              fullName: "Luciano Melandri Bobadilla",
             
            },
            {
              avatar:require( "../images/Foto 5 CARMEN.jpeg"),
              cv: "Magister en Comercio internacional & Community Manager. ",
              email: "carmenlda@novahome.cl",
              fullName: "Carmen López de Ahumada Ranchal",
              
            },
            {
              avatar:require( "../images/Foto 6 ANGIE.jpeg"),
              cv: "Abogada & Asesora inmobiliaria experta en negociación. ",
              email: "aizaguirre@novahome.cl",
              fullName: "Angie Izaguirre Altuve",
            
            },
            {
              avatar: require("../images/Foto 7 KAREN.jpeg"),
              cv: "Arquitecta tasadora & Asesora inmobiliaria con más de 15 años de experiencia. ",
              email: "ksteinert@novahome.cl",
              fullName: "Karen Steinert Lira",
             
            }, 
            {
              avatar: require("../images/Foto 8 JOANNA.jpeg"),
              cv: "Arquitecta tasadora & Asesora inmobiliaria con más de 15 años de experiencia. ",
              email: "jalvareznova@novahome.cl",
              fullName: "Joanna Álvarez Nova",
              
            },
            {
              avatar: require("../images/Foto 9 CLAUDIO.jpeg"),
              cv: "Administrador de empresas & Asesor inmobiliario con más de 20 años de experiencia en ventas.",
              email: "crojas@novahome.cl",
              fullName: "Claudio Rojas Quinteros",
              
            },
            {
              avatar: require("../images/Foto 10 ANA MARÍA.jpeg"),
              cv: "Asesora inmobiliaria con experiencia en captaciones y ventas consultivas.",
              email: "acarvacho@novahome.cl",
              fullName: "Ana María Carvallo Salazar",
              
            } ,
            {
              avatar: require("../images/Foto 11 JULLY.jpeg"),
              cv: "Profesional con 18 años de experiencia en atención y sevicio al cliente, captación de propiedades y especialista en seguimiento y gestión inmobiliaria.",
              email: "jcolmenares@novahome.cl",
              fullName: "Jully Colmenares",
              
            }                                       
          ]
        },
        ubication: {
          title:  data.about && data.about.ubication && data.about.ubication.title ? data.about.ubication.title : "¿Necesitas vender, arrendar o comprar una propiedad? Somos tu socio perfecto"
        }
      },
      contact: {
        map: {
          title: data.contact && data.contact.map && data.contact.map.title ? data.contact.map.title : "Encuéntranos en El Bosque Norte 211, Las Condes - Santiago",
          subTitle: data.contact && data.contact.map && data.contact.map.subTitle ? data.contact.map.subTitle : "234"
        },
        title: data.contact && data.contact.title ? data.contact.title : "¿Dudas? ¿Consultas? Estamos aquí para ayudarlo.",
        subTitle: data.contact && data.contact.subTitle ? data.contact.subTitle : "Envienos un mensaje y uno de nuestros asesores se pondra en contacto a la brevedad"        
      },
    }
  )
}